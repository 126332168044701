<template>
  <div>
    <v-dialog v-model="$store.state.dialog" width="800">
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title
            >{{
              $store.state.itemDetails.dialogType == "add"
                ? $t("idhafh")
                : $t("edit")
            }}
            {{ this.$route.meta.single }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form" >
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <h3 class="mb-2">{{ $t('alism') }} <span class="required">*</span></h3>
                    <v-text-field
                      v-model="item.name"
                      :placeholder="$t('alism')"
                      :rules="[$global.state.required()]"
                      required
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2" justify="end">
                <v-btn
                  @click="close()"
                  x-large
                  color="error"
                  class="mx-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
    };
  },

  mounted() {
    this.reset();

    this.$eventBus.$on("fill-fields", () => {
      console.log(this.$store.state.itemDetails);
      this.item = this.$store.state.itemDetails;
    });
  },

  methods: {
    reset() {
      this.item = {
        name: "",
      };
    },

    submit() {
      if (this.$store.state.itemDetails.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },

    addItem() {
      this.$global.state.loading = true;
      this.$http
        .post(`${this.$route.meta.endPoint}`, this.item)
        .then((res) => {
          this.$global.state.specialties = [];
          this.$service.success(this.$t("tm-alidhafh-bnjah"));
          this.$eventBus.$emit(`refresh`);
          this.close();
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    editItem() {
      this.$global.state.loading = true;
      this.$http
        .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
        .then((res) => {
          this.close();
          this.$service.success(this.$t('tm-altadyl-bnjah'))
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
